import React from 'react';
import classes from './styles.module.scss';
import GooglePlay from 'public/assets/images/google-play.svg';
import { PLAY_STORE_URL } from '../../config/index';

export default function PlayStoreButton() {
  return (
    <a
      className={classes.PlayStoreButton}
      target="_blank"
      rel="nofollow noreferrer noopener"
      href={PLAY_STORE_URL}
    >
      <GooglePlay />
    </a>
  );
}
