import site from 'config';

export const organization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Sikka',
  alternateName: 'Sikka App',
  url: site.siteMetadata.siteUrl,
  logo: site.siteMetadata.logo,
  sameAs: [
    'https://www.instagram.com/sikka_app/',
    'https://www.facebook.com/people/Sikka-App/100074959262484/'
  ]
};
