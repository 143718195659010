
export const softwareApplication = {
	"@context": "https://schema.org",
	"@type": "MobileApplication",
	name: "Sikka",
	operatingSystem: "ANDROID",
	applicationCategory: "https://schema.org/MobileApplication",
	aggregateRating: {
		"@type": "AggregateRating",
		ratingValue: "4",
		ratingCount: "41999",
	},
	offers: {
		"@type": "Offer",
		price: "0",
		priceCurrency: "INR",
	},
};
