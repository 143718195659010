import React from 'react';
import OfferRewardIcon from 'public/assets/icons/offer-reward.svg';
import DollarIcon from 'public/assets/icons/dollar.svg';
import ReferralIcon from 'public/assets/icons/referral.svg';
import GooglePlayGameIcon from 'public/assets/icons/google-play-game.svg';
import classes from './styles.module.scss';
import { PLAY_STORE_URL } from '../../config/index';
import PlanCard from 'components/PlanCard';
const PLANS = {
  sikka: {
    title: 'Sikka',
    submitButtonText: 'Download Sikka Now',
    submitButtonLink: PLAY_STORE_URL,
    features: [
      {
        title: 'Exciting offers',
        subtitle: 'Complete Offers and win Sikka',
        icon: OfferRewardIcon
      },
      {
        title: 'Up to 20 referrals',
        subtitle: 'Complete Offers with higher payout',
        icon: ReferralIcon
      },
      {
        title: 'Spin and win',
        subtitle: 'Refer and earn more reward',
        icon: DollarIcon
      },
      {
        title: 'High withdrawal limits',
        subtitle: 'Spin the wheel and earn Sikka',
        icon: GooglePlayGameIcon
      }
    ]
  }
};
export default function OurPlans(props) {
  return (
    <div className={classes.OurPlans}>
      <h2>Unlimited earnings with Our Sikka App</h2>
      <div className={classes.plans}>
        <PlanCard data={PLANS.sikka} />
      </div>
    </div>
  );
}
