import { useRouter } from 'next/router';
import React from 'react';
import Logo from 'public/assets/logo/logo.svg';
import ProLogo from 'public/assets/logo/pro-logo.svg';
import classes from './styles.module.scss';
import clsx from 'clsx';
import SKButton from 'components/UI/SKButton';
export default function PlanCard(props) {
  const router = useRouter();
  return (
    <div className={clsx(classes.PlanCard, props.pro && classes.ProCard)}>
      <div className={classes.rows}>
        {props.data.features.map(d => (
          <div className={classes.row} key={d.title}>
            <d.icon />
            <div className={classes.content}>
              <div className={classes.title}> {d.title}</div>
              {/* <div className={classes.subtitle}> {d.subtitle}</div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
