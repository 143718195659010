import React, { useState } from 'react';
import Layout from 'components/Layout';
import clsx from 'clsx';
import PlayStoreButton from 'components/PlayStoreButton';
import SKButton from 'components/UI/SKButton';
import OurPlans from 'components/OurPlans';
import { Text } from '@chakra-ui/layout';
import { useRouter } from 'next/router';
import classes from './styles.module.scss';
import NextImage from 'components/NextImage';
import { Button } from '@chakra-ui/react';
import useMediaQuery from 'hooks/useMediaQuery';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SEO from 'components/Seo';
import { softwareApplication } from 'data/Schema/homePageSchema';
import { organization } from 'data/Schema/commonSchema';
import { AiFillStar } from 'react-icons/ai';
import { IoMdShareAlt } from 'react-icons/io';
import { HiChevronDoubleDown } from 'react-icons/hi';
import Link from 'next/link';
import { SlideFade } from '@chakra-ui/react';
import { DOWNLOAD_PAGE } from 'constants/route.const';
import { useToast } from '@chakra-ui/react';
import { isDev } from '../config/index';
import SwicthToProdMode from 'components/SwitchToProdMode';
import { copyToClickBoard } from 'utils';

export default function HomePage() {
  const router = useRouter();

  const toast = useToast();

  const isTablet = useMediaQuery('(max-width: 1000px)');

  const [showContent, setShowContent] = useState(false);

  const bannerData = [
    {
      number: 10,
      suffix: 'L+',
      label: 'Daily Winnings'
    },
    {
      number: 1,
      suffix: 'Cr+',
      label: 'Installs'
    }
  ];

  // const videoData = [
  // 	{
  // 		id: 0,
  // 		name: "English 1",
  // 		link: "#",
  // 	},
  // 	{
  // 		id: 1,
  // 		name: "English 2",
  // 		link: "#",
  // 	},
  // 	{
  // 		id: 2,
  // 		name: "English 3",
  // 		link: "#",
  // 	},
  // 	{
  // 		id: 3,
  // 		name: "English 4",
  // 		link: "#",
  // 	},
  // ];

  // const [videoId, setVideoId] = useState(videoData[0]);

  const testimonialData = [
    {
      name: 'Satyavati Chandra',
      title: 'Using Sikka since July 2022',
      image: '/assets/images/testimonial/one.jpg',
      content:
        'I had doubts at the start, but the more tasks I did, the more I earned real money instantly in my wallet. Then I tried Sikka and started to earn real money in my bank account daily. It’s going to be a year since I started playing, and I have earned more than 1 lakh for free.'
    },
    {
      name: 'Vishvajit Anand',
      title: 'Using Sikka since Sept 2022',
      image: '/assets/images/testimonial/two.jpg',
      content:
        'I have been with Sikka for 6 months now and it has been a big earning daily, with instant money withdrawal to my Paytm account. Earning at least 30 rupees daily and making my free recharges with sikka free money earning app.'
    },
    {
      name: 'Narayanin Ranganathan',
      title: 'Using Sikka since June 2022',
      image: '/assets/images/testimonial/three.jpg',
      content:
        'I have been here for a short period now, but still I am sure that I am going to earn a lot for free with the help of daily rewards, daily spin wheel money & easy simple offers. There are easy tasks with a clear mention of how to make money and keep making easy money on Sikka for free.'
    },
    {
      name: 'Suresh Goswami',
      title: 'Using Sikka since Dec 2022',
      image: '/assets/images/testimonial/four.jpg',
      content:
        'A quick cash earning app, even you should try to use this referral code (UAQBLN). And a tip to earn a lot, keep doing daily tasks and refer more people as you will earn 100% of what they earn for free and make lots of money on this app, a great refer-and-earn real money with Sikka app as you can refer unlimited.'
    },
    {
      name: 'Deepali Kulkarni',
      title: 'Using Sikka since Aug 2022',
      image: '/assets/images/testimonial/five.jpg',
      content:
        'In the beginning I didn’t believe it, but when I received the payment in just a minute of time, I knew it was genuine. I 100% recommend this app to everyone. Hope the app doesn’t change in the way it gives points but to improve more. This app helps me. I love it, I just withdraw real money now for free, it’s my 2nd, 100% legit and free money earning app. All you have to do is just finish the tasks every day like checking in and playing games. Thank you so much Sikka, in withdrawing it takes minutes only Very trusted game app so far. And also, they give generous amounts of Sikka coins.'
    },
    {
      name: 'Sandeep Mukherjee',
      title: 'Using Sikka since Aug 2022',
      image: '/assets/images/testimonial/six.jpg',
      content:
        'Easy to learn how to make easy money daily. I am a student, and with the help of Sikka, I am making quick, easy free money daily, helping me add more cash to my wallet. Never thought it would be possible to get real money for free from an app and that for instantly in the bank wallet.'
    },
    {
      name: 'Brijesh Kumar',
      title: 'Using Sikka since June 2022',
      image: '/assets/images/testimonial/seven.jpg',
      content:
        'I joined sikka because my friend had referred me, and since then i started making money daily and referring more to earn extra money other then the offers that i am doing to make money which gets credited to my bank upi wallet. Once you complete all steps properly you get sikka coins instantly and instantly you can transfer to any wallet or bank account.'
    }
  ];

  const settings = {
    className: clsx('center ', classes.slide),
    centerMode: true,
    centerPadding: '-5px',
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 500,
    easing: 'ease',
    arrows: false,
    autoplay: true,
    dots: true,
    pauseOnHover: false,
    dotsClass: clsx('slick-dots ', classes.slickDots),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '100px',
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '60px',
          dots: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '40px',
          dots: true
        }
      }
    ]
  };

  const schemaCodes = [
    {
      type: 'application/ld+json',
      code: JSON.stringify(organization)
    },
    {
      type: 'application/ld+json',
      code: JSON.stringify(softwareApplication)
    }
  ];

  const [hover, setHover] = useState(false);

  const shareLink = () => {
    if (navigator.canShare) {
      navigator.share({
        url: 'https://play.google.com/store/apps/details?id=com.sikka.freemoney&utm_source=sikkaweb&utm_medium=websikka&utm_campaign=sikkaweborganic'
      });
    } else {
      //functionality for desktop
      copyToClickBoard(
        'https://play.google.com/store/apps/details?id=com.sikka.freemoney&utm_source=sikkaweb&utm_medium=websikka&utm_campaign=sikkaweborganic'
      );
      toast({ title: 'Link copied' });
    }
  };

  return (
    <Layout>
      <SEO
        useTitleTemplate={false}
        url={router.pathname}
        title="Best Earning App | Best Money Earning App - Sikka"
        description="Sikka is the best earning app to earn real cash rewards! All you need to do is complete simple offers daily to start earning money wallet. Download now."
        keywords={['Best Earning App', 'Online Money Earning App']}
        schemaCodes={schemaCodes}
        image={'/assets/images/hero-image-sikka.png'}
      />
      <section className={classes.bannerSection}>
        <div className={classes.banner}>
          <div className={classes.bannerContent}>
            <h1 className={classes.bannerHead}>
              India’s favorite Best Earning app!
            </h1>
            <p>
              Welcome to a world full of rewards. Download the best earning app
              now and stand a chance to earn money by performing simple tasks.
            </p>
            <div className={classes.bannerBtn}>
              {isTablet ? (
                <div
                  className={clsx(classes.mobileHoverBtn, classes.hoverCard)}
                >
                  <a href="https://play.google.com/store/apps/details?id=com.sikka.freemoney&utm_source=sikkaweb&utm_medium=websikka&utm_campaign=sikkaweborganic">
                    <NextImage
                      className={{ imageContainer: classes.qrImage }}
                      src={'/assets/images/sikka_qr_code.png'}
                      alt="QR Code Image"
                      title="Sikka QR Code"
                      fill
                    />
                  </a>
                  <PlayStoreButton />
                  <Button onClick={shareLink} className={classes.shareBtn}>
                    <IoMdShareAlt />
                    <span>Share the link</span>
                  </Button>
                </div>
              ) : (
                <SKButton
                  className={classes.hoverContainer}
                  onMouseEnter={() => setHover(true)}
                >
                  Download Sikka Now
                  <SlideFade
                    in={hover}
                    offsetY="20px"
                    reverse
                    unmountOnExit
                    className={clsx(classes.hoverCard)}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <a
                      target={'_blank'}
                      rel="noreferrer noopener nofollow"
                      href="https://play.google.com/store/apps/details?id=com.sikka.freemoney&utm_source=sikkaweb&utm_medium=websikka&utm_campaign=sikkaweborganic"
                    >
                      <NextImage
                        className={{ imageContainer: classes.qrImage }}
                        src={'/assets/images/sikka_qr_code.png'}
                        alt="QR Code Image"
                        title="Sikka QR Code"
                        fill
                      />
                    </a>
                    <PlayStoreButton />
                    <Button onClick={shareLink} className={classes.shareBtn}>
                      <IoMdShareAlt />
                      <span>Share the link</span>
                    </Button>
                  </SlideFade>
                </SKButton>
              )}
            </div>
            {!isTablet && (
              <>
                <div className={classes.bannerCounter}>
                  {bannerData.map(val => (
                    <div key={val.label} className={classes.counterCard}>
                      <p className={classes.counter}>
                        {val.number + val.suffix}
                      </p>
                      <p className={classes.label}>{val.label}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <NextImage
            className={{ imageContainer: classes.bannerImage }}
            src={'/assets/images/hero-image-sikka.png'}
            alt="India’s favorite Best Earning app!"
            title="India’s favorite Best Earning app!"
            fill
          />
        </div>
        {!isTablet && (
          <div id="video" className={classes.downBtn}>
            <Link href={'#video'}>
              <HiChevronDoubleDown />
            </Link>
          </div>
        )}
        {isDev && <SwicthToProdMode />}
      </section>
      <section className={classes.videoSection}>
        <h2 className={classes.heading}>Learn How to use</h2>
        <div className={classes.container}>
          {/* <div className={classes.videoNav}>
						{videoData.map((val, index) => (
							<Button
								key={val.id}
								variant="outline"
								className={videoId.id === index && classes.active}
								onClick={() => setVideoId(videoData[index])}
							>
								{val.name}
							</Button>
						))}
					</div> */}
          <div className={classes.reactPlayer}>
            <ReactPlayer
              width="100%"
              height="100%"
              url="https://www.youtube.com/watch?v=aJAqY9qcJbA"
              controls={true}
              playsinline={true}
              playing={false}
            />
          </div>
        </div>
      </section>
      <section className={classes.whatSikkaSection}>
        <h2 className={classes.heading}>Sikka - Best Money Earning App</h2>
        <p>
          Sikka is the best earning app with one-stop destination to earn
          fantastic cash rewards! All you need to do is complete simple offers
          and spin the wheel daily in the Sikka app. Accomplish bigger offers
          and earn more Sikka coins in your Sikka wallet! With the Unlimited
          Referrals feature, you can now refer Sikka to your buddies and enjoy
          as much as 50% of their earnings for FREE!
        </p>
        <NextImage
          className={{ imageContainer: classes.image }}
          src={'/assets/images/mid-image-sikka-official.png'}
          alt="Sikka - Best Money Earning App"
          title="Sikka - Best Money Earning App"
          fill
        />
      </section>
      <section className={classes.sliderSection}>
        <h2 className={classes.heading}>What people say about Sikka</h2>
        <div className={classes.sliderContainer}>
          <Slider {...settings}>
            {testimonialData.map((val, index) => (
              <div className={classes.card} key={index}>
                <div className={classes.header}>
                  <NextImage
                    className={{ imageContainer: classes.image }}
                    src={val.image}
                    alt="Testimonial Image"
                    fill
                  />
                  <div className={classes.headerContent}>
                    <Text className={classes.name}>{val.name}</Text>
                    <Text className={classes.title}>{val.title}</Text>
                  </div>
                </div>
                <div className={classes.footer}>
                  <div className={classes.star}>
                    {[...Array(5)].map((val, index) => (
                      <AiFillStar key={index} />
                    ))}
                  </div>
                  <Text className={classes.content}>{val.content}</Text>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className={classes.learnSection}>
        <h2 className={classes.heading}>Learn how to earn money online</h2>
        <div className={classes.content}>
          <p>
            Sikka is the best earning app where you can earn Sikka coins by
            completing simple offers with easy tasks. With Sikka, you are
            entitled to multiple ways of making cash rewards in your Sikka
            wallet that you can even withdraw to your bank account through UPI
            platforms.
          </p>
          <br />
          <p>
            Earn big with high-money earning offers on Sikka. Try your luck at
            Spin & Win, and get a chance to earn Sikka coins daily. Don’t miss
            the golden chance to earn Sikka coins by claiming your daily reward;
            the more you play, the more you make money.
          </p>
          <div className={showContent ? classes.show : classes.hide}>
            <p>
              Refer Sikka - the best money earning app, to your friends and gain
              a reward worth 50% of their earnings… for FREE!
            </p>
            <br />
            <p>
              All you earn is all yours! Transfer your earnings from the Sikka
              wallet to your bank account instantly with a straightforward
              Redeem Policy. Redeem Sikka coins to your bank account with UPI.
            </p>
          </div>
          {!showContent && (
            <Button variant={'link'} onClick={() => setShowContent(true)}>
              Know More
            </Button>
          )}
        </div>
      </section>
      <section className={classes.homeFooter}>
        <p>#joinsikkafamilynow</p>
      </section>
    </Layout>
  );
}
