import { Button } from '@chakra-ui/button';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal';
import React, { useRef, useState } from 'react';
// import { isDev,isProduction } from '../../config/index';
import { PRODUCTION_MODE } from '../../constants';
import { StorageService } from '../../service/Storage';
import { isProdMode } from '../../utils';

export default function SwicthToProdMode(props) {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef(null);
  
  function onClose() {
    setIsOpen(false);
  }

  function onProdMode() {
    StorageService.set(PRODUCTION_MODE, true);
    window.location.reload();
  }
  function onNormalMode() {
    StorageService.set(PRODUCTION_MODE, false);
    window.location.reload();
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: '0.3rem' }}
    >
      <Button
        colorScheme={isProdMode ? 'green' : 'red'}
        onClick={() => setIsOpen(true)}
        size="md"
      >
        Switch to {isProdMode ? 'normal' : 'production'} mode
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Switch to {isProdMode ? 'normal' : 'production'} mode ?
            </AlertDialogHeader>

            <AlertDialogBody>
              {isProdMode
                ? ' All the API calls will point to dev mode now.'
                : 'Are you sure? All the API calls will point to prod-api.'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} size="md">
                Cancel
              </Button>
              &nbsp;
              <Button
                colorScheme="red"
                onClick={isProdMode ? onNormalMode : onProdMode}
                size="md"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}
